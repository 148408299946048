import React from 'react'
import '../Footer.css';

const Footer = () => {
  return (
    <div className='footer flex-col items-center lg:flex-row lg:justify-around'>
        <span>copyright © 2023 angelab.dev</span>

    </div>
  )
}

export default Footer